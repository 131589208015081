@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
}

.header_Container {
  /* padding: 20px 10px 0px 10px; */
  padding-top: 8px !important;
  margin-top: 0px;
  background: linear-gradient(180deg,
      rgba(0, 0, 0, 0.7) 0%,
      rgba(102, 102, 102, 0) 100%);
      /* background-color: red; */
}

.header_Container_brown {
  padding-top: 8px !important;
  margin-top: 0px;
}

.header_logo {
  width: 135px;
}

.header_logo_mob {
  /* margin-top: 15vh; */
  margin-left: -17px;
  width: 50px;
}

.headerLink {
  color: #fff !important;
  font-family: Poppins !important;
  font-size: 14px !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 600;
  margin-right: 52px !important;
}

.headerLink1 {
  color: #000;
  font-family: Poppins;
  /* margin-top: 12px; */
  font-size: 14px !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 600 !important;
  margin-right: 52px !important;
}

.headerLink1-mob {
  margin-top: 12px;
}

.headerLink:hover {
  color: white !important;
}

.navitem-link {
  color: #22762b !important;
  text-decoration: none !important;
}

.navitem-link:hover {
  color: #22762b !important;
}

.headerLinkActive:hover {
  color: #22762b !important;
}

.home-landing-page {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-size: contain;
}

.event-landing-page {
  position: relative;
  width: 100%;
  /* background-image: url('./assests/images/bg.png') ; */
  height: 100vh;
  overflow: hidden;
  background-repeat: no-repeat;
  background-attachment: fixed;
  overflow: hidden;
  background-size: cover;
}

.about-us-vision-page {
  position: relative;
  width: 100% !important;
  /* background-image: url('./assests/images/bg.png') ; */
  height: 70vh;
  overflow: hidden;
  background-repeat: no-repeat;
  background-attachment: fixed;
  overflow: hidden;
  background-size: cover;
}

.work-landing-page {
  width: 100%;
  height: auto;
  background-color: rgba(187, 167, 149, 0.4);
}

.work-top-container {
  position: relative;
  height: 100vh;
  /* background-color: red; */
}

.state-work-top-container {
  position: relative;
  height: 90vh;
  /* background-color: red; */
}

.map-container-main {
  position: absolute;
  width: auto;
  height: auto;
  bottom: 5%;
  left: 20%;
  /* background-color: red; */
  z-index: 10;
}

.state-map-container-main {
  position: absolute;
  width: auto;
  height: auto;
  bottom: -5%;
  left: 10%;
  z-index: 10;
  /* background-color: red; */
}

.map-img-main {
  height: 80vh;
}

.map-pin-odisha {
  position: absolute;
  width: 20px;
  top: 55%;
  left: 53%;
}

.map-pin-andhra {
  position: absolute;
  width: 20px;
  top: 72%;
  left: 33%;
}

.map-pin-jharkhand {
  position: absolute;
  width: 20px;
  top: 44%;
  left: 56%;
}

.map-pin-hp {
  position: absolute;
  width: 20px;
  top: 16%;
  left: 30%;
}

.map-pin-goa {
  position: absolute;
  width: 20px;
  top: 71%;
  left: 16%;
}

.map-pin-mh {
  position: absolute;
  width: 20px;
  top: 57%;
  left: 21%;
}

.map-pin-andhra {
  position: absolute;
  width: 20px;
  top: 73%;
  left: 35%;
}

.work-landing-top {
  width: 100%;
  height: 90vh;
  background-image: url("./assests/images/work_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  justify-content: center;
  /* background-color: red; */
  /* background-position: center; */
}

.state-work-landing-top {
  width: 100%;
  height: 90vh;
  background-image: url("./assests/images/work_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  justify-content: center;
}

.top-details {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: end;
  position: absolute;
  bottom: 0%;
  /* background-color: rebeccapurple; */
}

.state-top-details {
  width: 90%;
  height: 70%;
  display: flex;
  justify-content: space-between;
  align-items: end;
  position: absolute;
  bottom: 0%;
  /* background-color: rebeccapurple; */
}

.top-details-info {
  width: 30%;
  font-size: 11px;
  font-family: Poppins;
  font-weight: 200;
  color: #fff;
  margin: 0;
  /* background-color: #fff; */
}

.state-top-details-info {
  width: 15%;
  font-size: 10px;
  font-family: Poppins;
  font-weight: 200;
  color: #fff;
  margin: 0;
  text-align: right;
  /* background: #ee9999; */
}

.top-details-info p {
  margin: 0;
  padding: 5px;
}

.state-top-details-info p {
  margin: 0;
  padding: 10px 15px;
}

.top-details-dropdown {
  width: 50%;
  font-family: Poppins;
  color: #fff;
  padding: 10px 10px 20px 10px;
  /* background-color: rgb(99, 100, 73); */
}

.state-top-details-dropdown {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: Poppins;
  color: #fff;
  padding: 10px 10px 20px 20px;
  /* background-color: rgb(99, 100, 73); */
}

.state-top-details-dropdown> :nth-child(1) {
  align-self: flex-end;
  margin-left: auto;
}

.top-details-dropdown p {
  font-size: 11px;
  font-family: Poppins;
  font-weight: 200;
}

.state-top-details-dropdown h2 {
  color: #7b3c2e;
  font-weight: 700;
  font-family: Poppins;
}

.state-top-details-dropdown p {
  font-size: 11px;
  font-family: Poppins;
  font-weight: 200;
}

.state-top-details-dropdown .read-more {
  color: #7b3c2e;
  font-weight: 500;
  font-style: italic;
}

.modal-content {
  color: #fff !important;
  font-family: Poppins;
  background-color: rgba(213, 104, 70, 0.9) !important;
}

.modal-title {
  font-size: 25px !important;
  font-weight: 700 !important;
}

.modal-header .btn-close {
  background-color: #fff !important;
}

.modal-body {
  font-size: 14px !important;
}

.custom-dropdown-toggle {
  background: rgb(255, 255, 255) !important;
  background: radial-gradient(circle,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0.40397408963585435) 100%) !important;
  color: rgb(0, 0, 0) !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  padding: 10px 30px !important;
  border: 2px solid #fff !important;
  border-radius: 50px !important;
  outline: none !important;
  box-shadow: none !important;
}

.custom-dropdown-toggle:hover {
  color: #fff !important;
  background: radial-gradient(circle, #da856150, #da8661) !important;
  transition: background 0.3s ease-in-out;
}

.custom-dropdown-toggle::after {
  color: #fff !important;
  margin-left: 50px !important;
  /* Adjust this value to increase or decrease the gap */
}

.custom-dropdown-menu {
  background-color: rgba(213, 104, 70, 0.9) !important;
  border: 2px solid #fff !important;
  border-radius: 8px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
  font-size: 14px !important;
}

.custom-dropdown-item {
  color: #ffffff !important;
  cursor: pointer !important;
}

.custom-dropdown-item:hover {
  background-color: #7b3c2e !important;
  color: white !important;
}

.dropdown-link {
  color: #ffffff;
  text-decoration: none;
}

.dropdown-link:hover {
  color: white;
  /* Matches the hover color for the items */
}

.work-landing-bottom {
  height: calc(100vh - 90vh);
  background-color: #ca9d8d;
}

.state-work-landing-bottom {
  height: calc(100vh - 90vh);
  background-color: #f9f2ef;
}

.work-card-section {
  width: 100%;
  display: flex;
  justify-content: center;
  background-image: url("./assests/images/work-cards-bg.png");
  background-repeat: no-repeat, repeat-y;
  background-size: cover, auto;
  background-position: center, center;
}

.work-grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  column-gap: 25px;
  row-gap: 20px;
  padding: 20px 0px 30px 0px;
  /* background-color: #e78b8b; */
}

.work-card-link {
  text-decoration: none;
}

.work-card {
  width: 300px;
  height: 300px;
  display: flex;
  border: 1.5px solid #7b3c2e;
  flex-direction: column;
  justify-content: space-between;
  background-color: transparent;
}

.work-card-img {
  width: 100%;
  height: 60%;
}

.work-card-img img {
  width: 100%;
  height: 100%;
  background-size: cover;
}

.work-card-title {
  width: 100%;
  height: 38%;
  padding: 15px 10px;
  background-color: rgb(255, 255, 255);
}

.work-card-title h6 {
  font-family: Poppins;
  font-weight: 500;
  font-size: 14px;
  color: #7b3c2e;
}

.state-work-card-section {
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: Poppins;
  background-color: #f9f2ef;
}

.state-work-container {
  width: 90%;
  display: flex;
  justify-content: space-between;
  padding: 25px 0;
  /* background-color: #cab1ab; */
}

.state-work-left-container {
  width: 42%;
  padding: 20px 15px 20px 0;
  border-right: 1.5px dashed #7b3c2e4a;
  /* background-color: rebeccapurple; */
}

.state-work-state-data h4 {
  font-weight: 700;
  color: #7b3c2e;
}

.state-work-state-data p {
  font-size: 12px;
  font-weight: 400;
  margin: 0;
}

.state-work-dist-data {
  margin-top: 30px;
}

.state-work-dist-data h6 {
  font-weight: 700;
  font-size: 13px;
  margin: 0;
}

.state-work-dist-data p {
  font-size: 12px;
  font-weight: 400;
  margin: 0;
}

.state-work-right-container {
  width: 55%;
  /* background-color: rgb(153, 109, 51); */
}

.state-work-grid-container {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 20px;
  row-gap: 20px;
  padding: 20px 0px;
  /* background-color: #e78b8b; */
}

.state-work-card {
  width: 300px;
  height: 300px;
  border: 1.5px solid #7b3c2e;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: transparent;
}

.state-work-card-img {
  width: 100%;
  height: 60%;
}

.state-work-card-img img {
  width: 100%;
  height: 100%;
  background-size: cover;
}

.state-work-card-title {
  width: 100%;
  height: 38%;
  padding: 15px 10px;
  background-color: rgb(255, 255, 255);
}

.state-work-card-title h6 {
  font-family: Poppins;
  font-weight: 700;
  font-size: 14px;
  color: #7b3c2e;
}

.state-work-detail-top {
  position: relative;
  width: 100vw;
  height: 90vh;
  overflow: hidden;
  background-size: contain;
}

.state-work-detail-top-image-exp {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.state-work-detail-top-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.state-work-detail-top-detail {
  position: absolute;
  width: 70%;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, 0%);
  text-align: center;
  z-index: 2;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  /* background-color: rgb(237, 135, 135); */
}

.state-work-detail-top-detail h2 {
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 2.4px;
  line-height: normal;
  margin-bottom: 15px;
}

.state-work-detail-top-detail h1 {
  font-size: 40px;
  font-weight: 800;
}

.state-work-detail-top-detail p {
  font-size: 10px;
  font-style: italic;
  font-weight: 300;
}

.state-work-detail-bottom {
  width: 100vw;
  background-color: #fff;
}

.state-work-detail-bottom-container {
  width: 90%;
  margin: auto;
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  font-family: Poppins;
  /* background-color: rgb(238, 135, 135); */
}

.detail-bottom-container-left {
  width: 75%;
  padding: 10px 20px;
  border-right: 1.8px dashed #7b3c2ecf;
  /* background-color: #65e071; */
}

.detail-bottom-container-left h4 {
  font-weight: 700;
  line-height: normal;
  color: #7b3c2e;
}

.detail-bottom-container-left p {
  font-size: 12px;
}

.detail-bottom-container-left .paragraph-heading {
  color: #7b3c2e;
  margin: 25px 0 0 0;
  font-weight: 700;
}

.detail-bottom-container-left .heading {
  width: calc(100% + 65px);
  /* Extend width to cover the padding */
  margin-left: -65px;
  /* Offset the margin to account for padding */
  padding: 15px 0 15px 65px;
  /* Maintain the inner padding */
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  background-color: rgba(123, 60, 46, 0.4);
}

.detail-bottom-container-left .highlighted-paragraph {
  width: calc(100% + 65px);
  /* Extend width to cover the padding */
  margin-left: -65px;
  /* Offset the margin to account for padding */
  padding: 5px 0 5px 65px;
  /* Maintain the inner padding */
  font-size: 12px;
  color: #7b3c2e;
  font-weight: 600;
  background-color: #f9f2ef;
}

.detail-bottom-container-left .tags-paragraph {
  width: calc(100% + 65px);
  /* Extend width to cover the padding */
  margin-left: -65px;
  /* Offset the margin to account for padding */
  padding: 15px 0 15px 65px;
  /* Maintain the inner padding */
  font-size: 14px;
  color: #7b3c2e;
  font-weight: 500;
  background-color: #f9f2ef;
}

.detail-bottom-container-left .tags-paragraph strong {
  font-size: 17px;
  font-weight: 600;
}

.detail-bottom-container-left .tags-para-tag-container {
  margin-bottom: 25px;
}

.tags-para-btn-tag {
  width: fit-content;
  padding: 8px 28px;
  border-radius: 30px;
  border: none;
  color: #d56846;
  font-size: 13px;
  font-weight: 500;
  background-color: rgba(218, 134, 97, 0.39);
}

.tags-para-btn-tag:nth-child(n + 2) {
  margin-left: 20px;
}

.detail-bottom-container-left .bullet-points {
  font-size: 12px;
  color: #7b3c2e;
  font-weight: 400;
}

.detail-bottom-container-left .about-paragraph-container {
  padding-left: 8px;
  border-left: 8px solid rgba(123, 60, 46, 0.4);
}

.detail-bottom-container-left .about-paragraph {
  font-size: 12px;
  /* background-color: #d56846; */
}

.back-work-btn {
  padding: 8px 24px;
  border-radius: 30px;
  border: none;
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  margin-top: 15px;
  background-color: #d56846;
}

.detail-bottom-container-right {
  width: 25%;
  padding: 20px 20px;
  /* background-color: #c6ea72; */
}

.work-detail-tag-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  /* background-color: rgb(235, 162, 162); */
}

.state-work-btn-tag {
  width: fit-content;
  padding: 8px 28px;
  border-radius: 30px;
  border: none;
  color: #d56846;
  font-size: 13px;
  font-weight: 500;
  margin-top: 20px;
  background-color: rgba(218, 134, 97, 0.39);
}

.home-project-text {
  color: #fff;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  margin-bottom: 5px;
}

.home-project-section {
  background: #d9ac98;
  overflow: hidden;
  padding: 35px 45px;
}

.home-page-title-row {
  position: absolute;
  width: 60%;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 2;
  /* background-color: rgb(237, 135, 135); */
}

.lib-page-title-row {
  position: absolute;
  width: 75% !important;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 2;
  /* background-color: rgb(237, 135, 135); */
}

.home-page-title-desc {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 9px;
  font-weight: 500;
  font-style: italic;
  line-height: normal;
  letter-spacing: 0.7px;
}

.vision-page-title-row {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100% !important;
  z-index: 2;
  width: 460px;
}

.vision-page {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  z-index: 2;
  width: 460px;
}

.library-page-title-row {
  position: absolute;
  top: 88%;
  left: 73%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 2;
  display: flex;
  width: 700px;
}

.library-page-title-row-mob {
  position: absolute;
  top: 80%;
  left: 53%;
  width: 100vw;
  transform: translate(-50%, -50%);
  z-index: 0;
  display: flex;
  padding-bottom: 5px;
  background: rgba(0, 0, 0, 0.4);
}

.home-page-title-column {
  width: 100px;
}

.home-page-title {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 2.4px;
  margin-bottom: 30px;
}

.library-page-title-1 {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  display: flex;
  justify-content: flex-end;
  line-height: normal;
  margin-bottom: 0px;
  letter-spacing: 2.4px;
}

.library-page-title-1-mob {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  display: flex;
  justify-content: flex-end;
  line-height: normal;
  margin-bottom: 0px;
  letter-spacing: 2.4px;
}

.library-page-title-2 {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  justify-content: flex-end;
  display: flex;
  line-height: normal;
  margin-bottom: 0px;
  letter-spacing: 2.4px;
}

.library-page-title-2-mob {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  justify-content: flex-end;
  display: flex;
  line-height: normal;
  margin-bottom: 0px;
  letter-spacing: 2.4px;
}

.library-page-title-3 {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  justify-content: flex-end;
  display: flex;
  line-height: normal;
  margin-bottom: 0px;
  letter-spacing: 2.4px;
  color: #fff;
  font-style: italic;
  margin-bottom: 0px;
}

.library-page-title-3-mob {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  justify-content: flex-end;
  display: flex;
  line-height: normal;
  margin-bottom: 0px;
  letter-spacing: 2.4px;
  color: #fff;
  font-style: italic;
  margin-bottom: 0px;
}

.library-page-button {
  border-radius: 30px;
  background: #d56846;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  padding: 7px 10px;
  border: none;
  width: 200px !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.library-page-button-div {
  display: flex;
  justify-content: flex-end;
  padding-top: 15px;
}

.library-page-button-div-mob {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}

.library-page-button-mob {
  border-radius: 30px;
  background: #d56846;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  padding: 7px 10px;
  border: none;
  width: 200px !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.our-stories {
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.our-stories-mob {
  color: #fff;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  width: fit-content;
}

.our-vision-div-mob {
  width: 85%;
  display: flex;
  justify-content: center;
  background: white;
  padding: 25px;
}

.our-stories-desc {
  color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.all-stories {
  border-radius: 30px;
  background: #fff;
  color: #d56846;
  font-family: Poppins;
  font-size: 12px;
  width: fit-content;
  padding: 5px 12px;
  border: none;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.our-vision-text {
  color: var(--Middle, #da8661);
  font-family: Poppins;
  font-size: 22px;
  width: 100px;
  display: flex;
  justify-content: flex-end;
  font-style: normal;
  font-weight: 700;
  line-height: 129.78%;
  /* 51.912px */
}

.home-page-title-div {
  width: 460px;
}

.our-vision {
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 0) -20.22%,
      #fff 100%);
  display: flex;
  align-items: flex-end;
}

.our-stories-col {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.home-page-button {
  border-radius: 30px;
  background: var(--Midium, #d56846);
  color: #fff;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  border: none;
  padding: 8px 32px;
  font-weight: 500;
  line-height: normal;
  transition: color 0.8s ease, background-color 0.8s ease;
}

.home-page-button:hover {
  color: #d56846;
  background-color: #fff;
  border: 1px solid #d56846;
  /* transition: color 0.8s ease, background-color 0.8s ease; */
}

.home-project-title-desc {
  color: #000;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.home-project-button {
  border-radius: 30px;
  background: #fff;
  color: var(--Midium, #d56846);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  border: none;
  font-weight: 500;
  line-height: normal;
  padding: 10px 82px;
  transition: color 0.8s ease, background-color 0.8s ease;
}

.home-project-button:hover {
  color: #fff;
  background-color: #d56846;
}

.home-project-desc-title {
  color: var(--Dark, #7b3c2e);
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 700;
  margin: 0px;
}

.home-publication-desc {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  width: 60%;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.home-project-desc-description {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 11px;
  padding: 2px 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.home-project-image {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.home-project-desc {
  background: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 3px 3px;
}

.home-project-column {
  padding: 4px !important;
  /* background-color: rgb(190, 223, 44); */
}

.img-col {
  width: 100%;
  height: 60%;
}

.desc-col {
  width: 100%;
  height: 40%;
}

.home-project-desc-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.library-video-section {
  padding-left: 10px !important;
}

.home-publication-title {
  color: var(--Dark, #7b3c2e);
  text-align: center;
  font-family: Poppins;
  width: 90%;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.home-pubs-section {
  width: 100%;
  min-height: 80vh;
  margin-top: 50px;
  /* background-color: #22762b; */
}

.lib-pubs-section {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  background-image: url("./assests/images/libraryBG.png");
  background-size: cover;
  background-position: center;
  /* background-color: #D9D9D9; */
}

.lib-bottom-section {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Poppins;
  background-color: #fffaf8;
}

.lib-bottom-container {
  width: 80%;
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-color: red; */
}

.lib-bottom-left {
  width: 60%;
  /* background-color: #7B3C2E; */
}

.lib-bottom-left h2 {
  letter-spacing: 1.5px;
  line-height: 1.5;
  color: #7b3c2e;
}

.lib-bottom-right {
  width: 30%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* background-color: yellowgreen; */
}

.lib-bottom-right input {
  width: 100%;
  font-size: 14px;
  padding: 6px 15px;
  border-radius: 30px;
  border: 2px solid #7b3c2e !important;
  outline: none;
  background-color: transparent;
}

.lib-bottom-right input::placeholder {
  color: #000;
  font-size: 13px;
  font-weight: 500;
  border: none;
  text-align: center;
}

.lib-subscription-button {
  width: 70%;
  color: #fff;
  font-size: 14px;
  padding: 9px;
  border-radius: 30px;
  border: none;
  background-color: #d56846;
}

.carousel .control-dots {
  display: none;
}

.home-pubs-division-image {
  width: 100%;
}

.home-pubs-division-desc {
  color: #000;
  font-family: Poppins;
  font-size: 11px !important;
  font-style: normal;
  padding: 5px;
  font-weight: 300;
  margin-top: 5px;
  line-height: normal;
}

.slick-prev:before,
.slick-next:before {
  content: "" !important;
}

.home-pubs-division-texts {
  text-align: left;
  padding: 10px;
  /* max-height: 70%; */
}

.home-pubs-division-texts-border {
  background: #fff;
  border-top: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 0px 0px 10px;
  border-top: 1px solid #7b3c2e;
  /* border-top: none !important; */
  /* border: 1px solid var(--Dark, #7B3C2E); */
}

.home-pubs-writer-name {
  color: #000;
  font-family: Poppins;
  font-size: 10px;
  margin-left: 7px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0px;
}

.home-pubs-writer-title {
  color: #000;
  font-family: Poppins;
  margin-left: 7px;
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 0px;
}

.carousel-container {
  max-width: 80%;
}

.home-pubs-division {
  height: 60vh;
  /* background-color: #7B3C2E; */
}

.stake-carousel-track {
  width: 100%;
  height: auto;
  margin: 35px 0px;
  /* background: #828eb9; */
}

.lib-stake-carousel-track {
  width: 100%;
  height: auto;
  /* margin: 35px 0px; */
  /* background: #828eb9; */
}

.crous-container {
  width: 70%;
  height: max-content;
  /* background-color: blue; */
}

.stake-carousel {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  height: 350px;
  border: 1px solid var(--Dark, #7b3c2e);
  background: rgba(218, 134, 97, 0.5);
}

.lib-stake-carousel {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  height: 350px;
  border: 1px solid var(--Dark, #7b3c2e);
  background: #fffaf8;
  cursor: pointer;
}

.carousel-dark {
  margin-bottom: 40px;
  background: rgba(218, 134, 97, 0.5);
}

.crous-container a {
  text-decoration: none !important;
}

.custom-slider .slick-slide {
  padding: 0 5px;
}

.custom-slider .slick-list {
  margin: 0 -5px;
}

.custom-slider-events .slick-slide {
  padding: 0 8px;
}

.custom-slider-events .slick-list {
  margin: 0 -8px;
}

.carousel .slide {
  text-align: inherit !important;
}

.carousel.carousel-slider {
  width: 70vw;
}

.slide {
  display: flex;
  align-items: center;
}

.carousel-root {
  width: 70vw;
  margin-top: 15px;
}

.carousel .thumbs {
  display: none;
}

.lib-detail-top {
  position: relative;
  width: 100vw;
  height: 90vh;
  overflow: hidden;
  background-size: contain;
}

.lib-detail-top-image-exp {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.lib-detail-top-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.lib-detail-top-detail {
  position: absolute;
  width: 70%;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, 0%);
  text-align: center;
  z-index: 2;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  /* background-color: rgb(237, 135, 135); */
}

.lib-detail-top-detail h2 {
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 2.4px;
  margin-bottom: 30px;
  line-height: normal;
  margin-bottom: 15px;
}

.lib-detail-top-detail p {
  font-size: 10px;
  font-style: italic;
  font-weight: 300;
}

.lib-detail-exp-top {
  height: 45vh;
  display: flex;
  justify-content: center;
}

.lib-detail-exp-top-detail {
  font-family: Poppins;
  display: flex;
  flex-direction: column;
  justify-content: end;
  text-align: center;
  width: 80%;
  bottom: 0;
  color: #7B3C2E;
}

.lib-detail-exp-top-detail h2 {
  letter-spacing: 2px;
  line-height: normal;
}

.lib-detail-exp-top-detail p {
  font-size: 11px;
  font-family: Poppins;
  font-style: italic;
}

.lib-detail-bottom {
  width: 100vw;
  font-family: Poppins;
  background-color: #F9F2EF;
}

.lib-detail-bottom-main-head {
  width: 100vw;
  text-align: center;
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  padding: 12px 0;
  background-color: #EEC3B1;
}

.lib-detail-bottom-description {
  width: 100vw;
}

.lib-detail-bottom-about-desc {
  padding: 30px 0;
  width: 80%;
  margin: auto;
  /* background-color: red; */
}

.lib-detail-bottom-about-desc p {
  margin: 0;
  font-size: 12px;
}

.lib-detail-bottom-tag-container {
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0 10px 0;
}

.lib-detail-bottom-btn-tag {
  font-size: 13px;
  font-weight: 700;
  border-radius: 30px;
  color: #D56846;
  border: 1px solid #7B3C2E;
  background-color: #fff;
  padding: 6px 24px;
  margin-right: 24px;
  margin-top: 20px;
}

.lib-detail-bottom-highlighted-sec {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.lib-detail-bottom-high-sec-dark {
  width: 100%;
  min-height: 120px;
  display: flex;
  background-color: rgba(238, 195, 177, 0.3);
}

.lib-detail-bottom-high-sec-light {
  width: 100%;
  min-height: 120px;
  display: flex;
  padding: 10px 0;
  background-color: transparent;
}

.lib-detail-bottom-high-left {
  width: 10%;
  padding: 0 8px 0 0;
  display: flex;
  align-items: center;
  justify-content: end;
}

.lib-detail-bottom-high-left p {
  text-align: end;
  font-weight: 300;
  color: #7B3C2E;
  font-size: 15px;
  margin: 0;
}

.lib-detail-bottom-high-right {
  width: 80%;
  padding-left: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-left: 8px solid rgba(123, 60, 46, 0.2);
}

.lib-detail-bottom-high-right p {
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.lib-detail-back-btn-div {
  display: flex;
  justify-content: center;
  margin: 10px 0 30px 0;
}

.back-lib-btn {
  padding: 8px 24px;
  border-radius: 30px;
  border: none;
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  background-color: #d56846;
}

.lib-detail-bottom-tag-container .paragraph-heading {
  color: #7B3C2E;
  font-size: 14px;
  font-weight: 700;
  margin-top: 30px;
}

.accordion-body .paragraph-heading {
  color: #7B3C2E;
  font-size: 14px;
  font-weight: 700;
  margin-top: 30px;
}

.lib-detail-bottom-tag-container .bullet-points {
  color: #7B3C2E;
  font-size: 12px;
}

.accordion-body .bullet-points {
  color: #7B3C2E;
  font-size: 12px;
}

.lib-detail-bottom-tag-container .paragraph {
  font-size: 12px;
}

.home-events-section {
  width: 100%;
  min-height: 70vh;
  background-size: cover;
  background-position: center;
  background-image: url("./assests/images/eventsBg.png");
  background-repeat: no-repeat;
  position: relative;
  /* background-color: rgb(207, 245, 160); */
}

.home-events-exp-section {
  background-color: #F9F2EF;
  background-image: url("../public/event-image/event-work-bg.png");
}

.home-detail-events-section {
  background-color: #F9F2EF;
}

.events-container {
  display: flex;
  justify-content: space-between;
  align-items: end;
  position: absolute;
  width: 90%;
  /* height: 40vh; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -30%);
  /* background-color: #9cf1a5; */
}

.events-slider {
  width: 60%;
  height: 100%;
  /* display: flex; */
  /* justify-content: space-between; */
  /* background-color: #f47979; */
}

.events-detail-slider {
  width: 100%;
  height: 100%;
  /* display: flex; */
  /* justify-content: space-between; */
  /* background-color: #f47979; */
}

.events-slider a {
  text-decoration: none;
}

.events-form {
  width: 35%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding: 0px 10px;
  /* background-color: red; */
}

.home-event-card {
  height: 100%;
  transition: 0.3s ease-in;
  /* padding-left: 10px; */
  /* padding-right: 10px; */
  /* width: 320px; */
  border: 3px solid #fff;
  background: rgba(123, 60, 46, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.event-slider-card {
  height: 250px;
  padding: 4px 12px;
  transition: 0.3s ease-in;
  color: #fff;
  font-family: Poppins;
  border: 3px solid #fff !important;
  background: rgba(123, 60, 46, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.card-info span {
  color: #000;
  font-size: 12px !important;
}

.card-info h1 {
  font-size: 50px !important;
  font-weight: 700;
  margin: 0px;
}

.card-info h6 {
  /* font-size: 50px !important; */
  font-weight: 700;
  margin-bottom: 20px;
}

.card-info p {
  font-size: 15px !important;
  font-weight: 200;
  margin-bottom: 20px;
}

.card-location-info {
  display: flex;
  justify-content: space-between;
  padding: o 4px;
  /* background: #000; */
}

.card-location-info img {
  width: 30px;
  height: 30px;
}

.card-location-icon {
  display: flex;
  align-items: end;
}

.card-location-icon p {
  margin: 0;
}

.home-event-card-status {
  color: #fff;
  font-family: Poppins;
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0px;
  padding-top: 25px;
  margin-left: 6px;
}

.home-event-card-location-section {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 40px;
  justify-content: space-between;
}

.home-event-card:hover {
  /* transform: scale(1.1); */
  background-color: #ea7055;
}

.home-event-section {
  width: 100%;
}

.home-workshop-button {
  margin-top: 20px;
}

.home-workshop-title {
  margin-left: 20px;
  margin-top: 30px;
}

.home-workshop-dotted-line {
  border: none;
  border-top: 3px dotted #7b3c2e;
  color: #fff;
  background-color: #fff;
  height: 1px;
}

.home-workshop-date {
  margin-bottom: 0px;
  text-align: center;
  color: var(--Dark, #7b3c2e);
  font-family: Poppins;
  width: 120px;
  font-size: 30px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.home-workshop-button1 {
  width: 90%;
}

.home-workshop-date-section {
  width: 100%;
  display: flex;
  justify-content: center;
}

.workshop-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 250px;
  font-family: Poppins;
  color: var(--Dark, #7b3c2e);
  /* background-color: rgb(142, 133, 92); */
}

.workshop-button {
  color: #fff;
  background-color: #d56846;
  padding: 8px 22px;
  border-radius: 30px;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  transition: color 0.8s ease, background-color 0.8s ease;
}

.workshop-button:hover {
  color: #d56846;
  background-color: #fff;
  border: 1px solid #d56846;
}

.workshop-bottom {
  width: 90%;
  text-align: end;
  /* background-color: yellow; */
}

.know-btn {
  padding: 8px 46px;
  text-align: end;
}

.workshop-middle {
  width: 90%;
  height: 50%;
  background-color: rgb(255, 255, 255);
  display: grid;
  grid-template-columns: 1fr 2.5fr 0.8fr;
  /* Adjust column widths */
  align-items: center;
  /* Vertically center content */
  gap: 2px;
  /* Space between grid items */
}

.workshop-middle h1 {
  font-size: 50px !important;
  font-weight: 800;
  margin: 0px;
}

.workshop-middle h3 {
  font-size: 25px !important;
  font-weight: 400;
  margin: 0px;
}

.work-mid {
  width: 100%;
  margin-top: 40px;
  /* background-color: red; */
}

.work-mid p {
  font-weight: 300;
  text-align: right;
  margin: 0;
}

.work-dotted-line {
  border: none;
  border-top: 3px dotted #7b3c2e;
  border-color: #7b3c2e;
  margin: 0;
  background-color: transparent;
  opacity: 1;
}

.work-right {
  text-align: center;
}

.footer-main-section {
  background: var(--Dark, #7b3c2e);
  overflow: hidden;
  padding: 20px;
  height: 100%;
  width: 100vw;
}

.footer-logo {
  width: 200px !important;
  margin: 0;
}

.footer-contact-us {
  width: fit-content !important;
}

.background-video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.background-video-cp {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: contain;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.home-workshop-title-year {
  color: var(--Dark, #7b3c2e);
  font-family: Poppins;
  font-size: 50px !important;
  font-style: normal;
  font-weight: 800;
  margin-bottom: 0px;
  line-height: 127%;
  /* 121.92px */
}

.home-workshop-title-name {
  color: var(--Dark, #7b3c2e);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 127%;
  /* 60.96px */
}

.home-event-title-name {
  color: #fff;
  font-family: Poppins;
  font-size: 26px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-shadow: 1px 1px 0 #E68662;
}

.home-event-title-desc {
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.home-event-card-division {
  display: flex;
  width: 100%;
  overflow-x: scroll;
  /* height: fit-content;
  width: 100vw;
  overflow-x: scroll; */
}

.home-event-card-mob {
  height: 300px;
  transition: 0.3s ease-in;
  padding-left: 15px;
  padding-right: 15px;
  width: 320px;
  border: 3px solid #fff;
  background: rgba(123, 60, 46, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.home-event-card-division::-webkit-scrollbar {
  display: none;
}

.event-subscription-input {
  width: 100%;
  border-radius: 50px !important;
  border: 2px solid #fff !important;
  background: transparent !important;
}

.event-subscription-input::placeholder {
  color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
}

.event-subscription-button {
  border-radius: 30px;
  color: var(--Midium, #d56846);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 50%;
  padding: 7px;
  border: none;
  margin-top: 10px;
  background: #fff;
}

.contact-us-icon {
  width: 18px;
  margin-right: 12px;
  margin-top: 10px;
}

.contact-us-text {
  color: #fff;
  font-family: Poppins;
  font-size: 13px;
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.contact-icons {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  /* background-color: rgb(177, 227, 102); */
  padding: 8px;
  /* margin-top: auto !important; */
  /* margin-bottom: auto; */
}

.footer-link {
  margin-bottom: 8px;
  font-family: Poppins;
  color: white;
  font-size: 12px;
  font-weight: 300;
}

.footer-link-head {
  margin-bottom: 25px;
  font-family: Poppins;
  color: white;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
}

.footer-link-section {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* background-color: rgb(130, 192, 237); */
}
.footer-link-section a {
  text-decoration: none;

}

/* .border-link-section{
  border: none !important;
  border-left: 1px solid #fff !important;
  border-left-style: dashed !important;
  } */
.work-map-image {
  width: 75vh;
}

.work-page-title-div {
  width: 460px;
}

.work-page-title-row {
  height: 72vh;
  align-content: flex-end;
  justify-content: center;
}

.work-page-title-column {
  width: 100px;
}

.background-video-1 {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.mob-background-video-1 {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.work-home-section {
  width: 100%;
  height: 100vh;
}

.roundtable-home-section {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

mob-work-home-section {
  width: 100%;
  height: 100vh;
}

.map-image-title {
  color: #fff;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.field-map-image-title {
  color: var(--Dark, #7b3c2e);
  font-family: Poppins;
  font-size: 27px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.work-map-dropdown-div {
  position: absolute;
  top: 25%;
  left: 78%;
}

.map-image-desc {
  color: #fff;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.field-map-image-desc {
  color: #fff;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.map-image-text {
  width: 500px;
  margin-bottom: 50px;
}

.field-map-image-text {
  margin-left: 30px;
  width: 600px;
  /* margin-bottom: 50px; */
}

.work-map-container {
  display: flex;
  height: 100vh;
  align-items: flex-end;
  justify-content: center;
}

.roundTable-map-container {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.mob-work-map-container {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.home-pubs-division-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.home-pubs-division-title {
  color: var(--Dark, #7b3c2e);
  font-family: Poppins;
  font-size: 13.5px;
  margin-top: 10px;
  margin-bottom: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.home-pubs-division-desc {
  color: #000;
  margin-bottom: 5px;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.home-pubs-division-1 {
  margin-top: 0px;
  border: none;
  margin-bottom: 20px;
}

.mob-home-pubs-division-1 {
  margin-top: 0px;
  border: none;
  padding-left: 30px;
  padding-right: 30px;
  /* margin-top: -100px; */
  margin-bottom: 20px;
}

.home-pubs-division-texts-1 {
  height: 120px;
  background: var(--Background, #fff);
  padding: 5px;
}

.work-home-map-button {
  border-radius: 30px;
  background: transparent;
  border: 1px solid var(--Dark, #7b3c2e);
  color: #000;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  padding-left: 15px;
  padding-top: 5px;
  float: right;
  padding-bottom: 5px;
  padding-right: 15px;
  margin-bottom: 20px;
  margin-right: 5px;
  font-weight: 500;
  line-height: normal;
}

.odisha-map-locations {
  width: 70%;
}

.odisha-work-map-container {
  /* justify-content: center; */
  /* align-items: center !important; */
}

.state-map-name {
  color: var(--Dark, #7b3c2e);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  width: 50%;
  line-height: normal;
}

.state-map-desc {
  color: #000;
  font-family: Poppins;
  font-size: 13px;
  width: 50%;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.navbar-toggler {
  background-color: none !important;
  border: none !important;
}

.carousel .control-prev.control-arrow {
  display: none;
}

.carousel .control-next.control-arrow {
  display: none;
}

.mob-map-image-title {
  color: #fff;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  margin-top: 20px;
  margin-left: 5px;
  line-height: normal;
}

.mob-map-image-desc {
  color: #fff;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-left: 5px;
  width: 100%;
}

.toggler-img {
  width: 100%;
  height: 75vh;
  object-fit: cover;
}

.home-project-title-desc-mob {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 13px;
  width: 70%;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.bunch-section {
  width: 100vw;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9f2ef;
}

.bunch-info-container {
  width: 70%;
  text-align: center;
  font-family: Poppins;
}

.bunch-info-container h3 {
  font-size: 28px;
  letter-spacing: 2.5px;
  color: #7b3c2e;
}

.bunch-info-container p {
  font-size: 13px;
  color: #7b3c2e;
}

.bunch-info-container .meet-btn {
  font-size: 12px;
  color: #fff;
  background-color: #d56846;
  padding: 8px 38px;
  border: none;
  border-radius: 30px;
}

.vision-section {
  width: 100vw;
  height: 80vh;
  /* background-color: #c5f7ca; */
}

.vision-video-container {
  width: 100% !important;
  height: 100%;
  /* background-color: #22762b; */
  position: relative;
  font-family: Poppins;
  color: #fff;
}

.vision-video {
  width: 100% !important;
  height: 100%;
  object-fit: cover;
  position: absolute;
}

.vision-video-content {
  position: absolute;
  width: 50%;
  height: 100%;
  right: 2%;
  padding: 40px 25px;
  background: rgb(123, 60, 46);
  background: linear-gradient(90deg,
      rgba(123, 60, 46, 1) 0%,
      rgba(225, 110, 84, 0.751313025210084) 100%);
}

.vision-video-content h3 {
  font-weight: 700;
}

.vision-video-content p {
  font-size: 13px;
  font-weight: 300;
}

.impact-section {
  width: 100vw;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("./assests/images/libraryBG.png");
  background-size: cover;
  background-position: center;
  font-family: Poppins;
  background-color: #f9f2ef;
}

.impact-top-section h3 {
  letter-spacing: 1px;
  color: #7b3c2e;
}

.impact-container {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  /* background-color: rgb(225, 70, 70); */
}

.impact-top-section {
  text-align: center;
}

.impact-top-section .join-btn {
  font-size: 12px;
  color: #fff;
  background-color: #d56846;
  padding: 8px 68px;
  margin-top: 10px;
  border: none;
  border-radius: 30px;
}

.impact-cards-section {
  display: grid;
  grid-template-columns: auto auto auto;
  column-gap: 15px;
}

.impact-card {
  min-height: 300px;
  width: 280px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  padding: 15px;
  background-color: rgba(123, 60, 46, 0.4);
  border: 2.5px solid #fff;
}

.impact-card h4 {
  color: #fff;
  font-weight: 700;
  margin-top: 20px;
}

.impact-card p {
  font-size: 12px;
}

.impact-card-btn {
  font-size: 12px;
  font-weight: 500;
  padding: 7px 0px;
  border-radius: 30px;
  width: 80%;
  color: #d56846;
  border: none;
  background-color: #fff;
}

.engage-section {
  width: 100vw;
  height: 100px;
  display: flex;
  justify-content: start;
  align-items: center;
  font-family: Poppins;
  background-color: #f9f2ef;
}

.engage-section .left-contianer {
  color: #7b3c2e;
  width: 82%;
}

.engage-section .left-contianer p {
  margin: 0px 0 0 80px;
}

.engage-section .left-contianer hr {
  border: none;
  border-top: 3px dotted #7b3c2e;
  border-color: #7b3c2e;
  margin: 0;
  background-color: transparent;
  opacity: 1;
}

.engage-section .right-contianer button {
  border: none;
  color: #fff;
  font-size: 12px;
  padding: 8px 36px;
  border-radius: 30px;
  background-color: #d56846;
}

.team-section {
  width: 100vw;
  height: auto;
  padding: 30px 0;
  font-family: Poppins;
  background-color: #f9f2ef;
}

.team-section h3 {
  font-size: 30px;
  text-align: center;
  color: #7b3c2e;
  letter-spacing: 1.5px;
}

.team-details-section {
  width: 100%;
  height: auto;
}

.team-info-container {
  width: 100%;
  height: 180px;
  position: relative;
  background-color: #fff;
  background: linear-gradient(to bottom,
      #f9f2ef 50%,
      rgba(217, 172, 152, 0.2) 50%);
}

.team-grid-section {
  position: absolute;
  width: 70%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: Poppins;
  display: flex;
  justify-content: space-between;
}

.team-info-space {
  width: 150px;
  text-align: center;
}

.team-info-space img {
  width: 85px;
  height: 85px;
}

.particulars-name {
  margin: 5px 0 0 0;
  font-size: 12px;
  font-weight: 700;
}

.particulars-pos {
  margin: 0;
  font-size: 10px;
  font-weight: 300;
}

.team-contact-section {
  width: 100vw;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9f2ef;
}

.team-contact-container {
  width: 80%;
  height: 80%;
  display: flex;
  justify-content: space-between;
  font-family: Poppins;
  position: relative;
  /* background-color: #D56846; */
}

.team-contact-vecor {
  position: absolute;
  top: 30%;
  left: -13%;
  /* background-color: #b090902d; */
}

.team-contact-vecor .team-contact-dot {
  width: 500px;
}

.team-contact-vecor .team-contact-badge {
  position: absolute;
  top: 35%;
  right: 9%;
  width: 40px;
}

.team-contact-left {
  width: 30%;
  height: 100%;
  /* background-color: rgb(222, 91, 91); */
}

.team-contact-left h2 {
  letter-spacing: 1.5px;
  line-height: 45px;
  color: #7b3c2e;
}

.team-contact-left p {
  font-size: 14px;
  color: #000;
}

.team-contact-right {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* background-color: rgb(222, 185, 91); */
}

.team-contact-right input {
  width: 100%;
  padding: 8px 20px;
  border: 2px solid #7b3c2e;
  border-radius: 20px;
  outline: none;
  background-color: transparent;
  font-size: 14px;
  font-weight: 500;
}

.team-contact-right input::placeholder {
  font-size: 14px;
  font-weight: 500;
  color: #000;
}

.team-contact-right textarea {
  width: 100%;
  padding: 5px 20px;
  border: 2px solid #7b3c2e;
  border-radius: 20px;
  outline: none;
  background-color: transparent;
  font-size: 14px;
  font-weight: 500;
}

.team-contact-right textarea::placeholder {
  font-size: 14px;
  font-weight: 500;
  color: #000;
}

.team-contact-right .sbt-btn {
  font-size: 12px;
  color: #fff;
  background-color: #d56846;
  padding: 8px 0px;
  border: none;
  border-radius: 30px;
  width: 50%;
}

.event-top-container {
  font-family: Poppins;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-image: url("../public/event-image/event-top-main-img.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: 30vh;
  /* background-color: rgb(234, 122, 122); */
}

.event-round-section {
  width: 100vw;
  min-height: 100vh;
  padding: 35px 0;
  background-color: #F9F2EF;
}

.event-round-container {
  margin: auto;
  width: 70%;
  height: 100%;
  font-family: Poppins;
  /* background-color: #d56846; */
}

.event-round-info-container {
  margin: auto;
  width: 70%;
  text-align: center;
  /* background-color: #e5ac9f; */
}

.event-round-info-container h4 {
  font-weight: 700;
  color: #7B3C2E;
}

.event-round-info-container p {
  font-size: 12px;
}

.round-div-container {
  display: grid;
  grid-template-columns: auto auto auto;
  row-gap: 40px;
  column-gap: 60px;
  justify-content: center;
  margin-top: 30px;
  /* background-color: rgb(234, 166, 166); */
}

.round-div-container p {
  font-size: 12px;
  margin: 0;
}

.round-circle-light {
  position: relative;
  width: 200px;
  /* Cap the max width for large screens */
  height: 200px;
  border-radius: 50%;
  background-color: rgba(249, 108, 77, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #7B3C2E;
  font-weight: 500;
  font-size: 16px;
  border: 2px solid #fff;
  padding: 10px;
  cursor: pointer;
}

.small-lt-circle {
  position: absolute;
  width: 4%;
  /* Relative size to main circle */
  height: 4%;
  border-radius: 50%;
  background-color: rgba(249, 108, 77, 0.4);
}

.small-lt-circle-1 {
  bottom: 20%;
  left: 0%;
  /* background-color: red; */
}

.small-lt-circle-2 {
  bottom: 12%;
  left: 6%;
  /* background-color: rgb(255, 196, 0); */
}

.small-lt-circle-3 {
  bottom: 5%;
  left: 14%;
  /* background-color: rgb(119, 255, 0); */
}

.small-lt-circle-4 {
  bottom: -1%;
  left: 24%;
  /* background-color: rgb(0, 255, 234); */
}

.small-lt-circle-5 {
  bottom: 20%;
  right: 1%;
  /* background-color: #d56846; */
}

.small-lt-circle-6 {
  bottom: 28%;
  right: -3%;
  /* background-color: #4650d5; */
}

.small-lt-circle-7 {
  bottom: 37%;
  right: -5%;
  /* background-color: #d546d0; */
}

.round-circle-dark {
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: #EEC3B1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #7B3C2E;
  font-weight: 500;
  font-size: 16px;
  border: 2px solid #fff;
  padding: 10px;
  cursor: pointer;
}

.small-dk-circle {
  position: absolute;
  width: 4%;
  /* Relative size to main circle */
  height: 4%;
  border-radius: 50%;
  background-color: #EEC3B1;
}

.small-dk-circle-1 {
  top: 10%;
  left: 8%;
  /* background-color: red; */
}

.small-dk-circle-2 {
  top: 18%;
  left: 2%;
  /* background-color: rgb(255, 196, 0); */
}

.small-dk-circle-3 {
  bottom: 5%;
  left: 14%;
  /* background-color: rgb(119, 255, 0); */
}

.small-dk-circle-4 {
  bottom: -1%;
  left: 24%;
  /* background-color: rgb(0, 255, 234); */
}

.small-dk-circle-5 {
  bottom: 0%;
  right: 22%;
  /* background-color: #d56846; */
}

.small-dk-circle-6 {
  bottom: 28%;
  right: -3%;
  /* background-color: #4650d5; */
}

.small-dk-circle-7 {
  top: 25%;
  right: -2%;
  /* background-color: #d546d0; */
}

.event-workshop-section {
  width: 100vw;
  min-height: 90vh;
  background-color: #D9AC98;
}

.event-workshop-container {
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin: auto;
  font-family: Poppins;
  text-align: center;
  padding: 20px 0;
  /* background-color: red; */
}

.event-workshop-container h4 {
  color: #fff;
  font-weight: 700;
}

.event-workshop-card-container {
  display: grid;
  grid-template-columns: auto auto auto;
  row-gap: 40px;
  column-gap: 60px;
  justify-content: center;
  margin-top: 30px;
  /* background-color: rgb(234, 166, 166); */
}

.event-workshop-card-dark {
  width: 180px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #fff;
  padding: 10px;
  background-color: rgba(123, 60, 46, 0.6);
  cursor: pointer;
}

.event-workshop-card-dark p {
  color: #fff;
  font-size: 12px;
}

.event-workshop-card-light {
  width: 180px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #fff;
  padding: 10px;
  background-color: rgba(123, 60, 46, 0.4);
  cursor: pointer;
}

.event-workshop-card-light p {
  color: #fff;
  font-size: 12px;
}

.event-main-bottom {
  width: 100vw;
  height: 80px;
  position: relative;
}

.event-main-bottom div {
  position: absolute;
  top: 50%;
  width: 100%;
  opacity: 1;
  border-top: 4px dotted #7b3c2e;
  background-color: transparent;
}

.event-main-bottom img {
  width: 60px;
  position: absolute;
  top: 15%;
  left: 40%;
}

.accordion-item {
  font-family: Poppins;
  background-color: #F9F2EF !important;
}

.accordion-header .accordion-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700 !important;
  font-size: 20px !important;
  background-color: var(--header-bg-color, #c3c0c0) !important;
  color: #FFF;
  border: none !important;
  box-shadow: none;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
  width: 100%;
  outline: none;
}

.accordion-button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.accordion-button::after {
  display: none !important;
}

.accordion-button:not(.collapsed) {
  color: #7B3C2E !important;
  background-color: #c3c0c0;
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
}

.accordion-body {
  width: 80%;
  margin: 0 auto;
  text-align: left;
  padding: 20px;
  font-size: 12px;
}

.accordion-item .accordion-button {
  border-radius: 0 !important;
}

.accordion-item {
  border-bottom: none !important;
}

.accordion-body .event-dt-image {
  width: 100%;
  margin-bottom: 10px;
}

@media screen and (max-width: 787px) {
  .stake-carousel {
    height: 400px;
  }

  .lib-stake-carousel {
    height: 400px;
  }

  .mob-field-location-col {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .about-team-contact-title {
    color: var(--Dark, #7b3c2e);
    font-family: Poppins;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 500;
    width: 100% !important;
    line-height: normal;
    letter-spacing: 2.4px;
    padding-left: 10px !important;
  }

  .about-team-contact-desc {
    color: #000;
    font-family: Poppins;
    padding-left: 10px !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 0px;
    line-height: normal;
  }

  .about-team-contact-image {
    display: none;
  }

  .about-team-form {
    padding-left: 10px !important;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .about-contact-input {
    border-radius: 60px !important;
    border: 1.5px solid #7b3c2e !important;
    width: 85% !important;
    margin-top: 13px;
    background: transparent !important;
  }

  .event-roundtable-1-desc {
    border-radius: 500px;
    border: 2px solid #fff;
    width: 150px !important;
    height: 150px !important;
    background: rgba(249, 108, 77, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .about-contact-input-submit {
    width: 85%;
    padding-left: 7.5%;
  }

  .team-tagline {
    color: var(--Dark, #7b3c2e);
    text-align: center !important;
    font-family: Poppins;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: fit-content !important;
    letter-spacing: 2.4px;
    margin-top: 20px !important;
  }

  .vision-page-title-text {
    text-align: center !important;
  }

  .vision-page-title-desc {
    width: 100% !important;
  }

  .join-us-title {
    text-align: center !important;
  }

  .vision-container {
    width: 100% !important;
  }
}

@media screen and (max-width: 480px) {
  .stake-carousel {
    height: 400px;
  }

  .lib-stake-carousel {
    height: 400px;
  }

  .map-image-text {
    width: 350px;
    margin-bottom: 50px;
  }

  .home-page-button {
    font-size: 15px;
  }

  .about-team-contact-title {
    color: var(--Dark, #7b3c2e);
    font-family: Poppins;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 500;
    width: 100% !important;
    line-height: normal;
    letter-spacing: 2.4px;
    padding-left: 10px !important;
  }

  .about-team-contact-desc {
    color: #000;
    font-family: Poppins;
    padding-left: 10px !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 0px;
    line-height: normal;
  }

  .about-team-contact-image {
    display: none;
  }

  .about-team-form {
    padding-left: 10px !important;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .about-contact-input {
    border-radius: 60px !important;
    border: 1.5px solid #7b3c2e !important;
    width: 85% !important;
    margin-top: 13px;
    background: transparent !important;
  }

  .event-roundtable-1-desc {
    border-radius: 500px;
    border: 2px solid #fff;
    width: 150px !important;
    height: 150px !important;
    background: rgba(249, 108, 77, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .about-contact-input-submit {
    width: 85%;
    padding-left: 7.5%;
  }

  .event-roundtable-1-desc-text {
    color: var(--Dark, #7b3c2e);
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1px;
    padding-left: 17px;
    font-size: 8px !important;
  }

  .hp_colab_text {
    text-align: left !important;
    padding-top: 10px;
    padding-left: 15px;
    font-size: 18px !important;
  }

  .toggler-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .blog-carousel-row {
    height: 350px !important;
  }

  .header_logo {
    width: 105px;
  }

  .home-page-title {
    font-size: 16px;
  }

  .home-landing-page {
    height: 70vh;
  }

  .home-page-title-row {
    position: absolute;
    width: 90%;
    left: 52%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 0;
  }

  .home-publication-desc {
    width: 90%;
  }

  .event-subscription-input {
    width: 80% !important;
  }

  .event-subscription-button {
    width: 60% !important;
  }

  .home-event-title-desc {
    font-size: 14px;
    width: 300px;
  }

  .home-event-title-name {
    margin-top: 20px;
  }

  .footer-link-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 40px !important;
  }

  .footer-logo {
    width: 180px !important;
    margin-left: 30px;
  }

  .work-map-image {
    width: 300px;
  }

  .map-image-desc {
    display: none;
    margin-left: -15px;
  }

  .map-image-title {
    font-size: 12px;
    margin-left: -20px;
  }

  .field-map-image-title {
    font-size: 12px;
    margin-left: -20px;
  }

  .work-home-section {
    height: 60vh;
  }

  .work-map-container {
    height: 60vh;
  }

  .mob-work-map-container {
    height: 80vh;
  }

  .home-publication-title {
    width: 100%;
  }

  .mob-field-location {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .mob-field-desc-location-button {
    margin-bottom: 20px !important;
  }

  .about-the-project-text {
    width: 100% !important;
  }

  .blog-desc-text-division {
    width: 100% !important;
  }

  .blog-title-text {
    padding-left: 10px;
    text-align: left !important;
  }

  .blog-field-division {
    width: 100% !important;
    padding: 25px 30px 25px 17px;
  }

  .blog-field-division_blank {
    display: none;
  }

  .blog-field-division-row {
    justify-content: center !important;
  }

  .blog-desc-text {
    padding: 10px !important;
  }

  .about-the-project-text-row-1 {
    justify-content: center;
  }

  .about-the-project-text-row-2 {
    justify-content: center;
    align-items: center;
  }

  .blog-details-2019 {
    background: #d9ac98;
    height: 120px;
    width: 60% !important;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    transition: 0.3s ease-in;
  }

  .blog-details-2019 {
    background: #d9ac98;
    height: 120px;
    width: 60% !important;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    transition: 0.3s ease-in;
  }

  .blog-details-2019-not-active {
    background: #d9ac98;
    height: 120px;
    width: 20% !important;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    transition: 0.3s ease-in;
  }

  .blog-details-2019-not-active p {
    color: var(--Dark, #7b3c2e);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  .blog-details-2019 p {
    color: #000;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .blog-details-2021 {
    background: var(--Light, #e68662);
    height: 120px;
    display: flex;
    justify-content: flex-end;
    transition: 0.3s ease-in;
    align-items: flex-end;
    width: 60% !important;
  }

  .blog-details-2021 p {
    color: #000;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .blog-details-2021-not-active {
    background: var(--Light, #e68662);
    height: 120px;
    display: flex;
    justify-content: flex-end;
    transition: 0.3s ease-in;
    align-items: flex-end;
    width: 20% !important;
  }

  .blog-details-2021-not-active p {
    color: var(--Dark, #7b3c2e);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  .blog-details-2023 {
    background: #f7c9b4;
    height: 120px;
    display: flex;
    transition: 0.3s ease-in;
    justify-content: flex-end;
    align-items: flex-end;
    width: 60% !important;
  }

  .blog-details-2023 p {
    color: #000;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .blog-details-2023-not-active {
    background: #f7c9b4;
    height: 120px;
    display: flex;
    transition: 0.3s ease-in;
    justify-content: flex-end;
    align-items: flex-end;
    width: 20% !important;
  }

  .blog-details-2023-not-active p {
    padding-right: 10px;
    color: var(--Dark, #7b3c2e);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  .our-stories-col {
    padding: 15px;
  }

  .jharfra-text {
    padding-left: 15px !important;
    padding-right: 15px !important;
    border-right: none;
    border-right-style: none;
  }

  .blog-details-button-mob {
    display: block !important;
  }

  .blog-details-button-mob-1 {
    display: none;
  }

  .bunch-section {
    height: 280px;
  }

  .bunch-info-container {
    width: 90%;
  }

  .vision-section {
    height: 95vh;
  }

  .vision-video-content {
    position: absolute;
    width: 80%;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 25px 15px;
  }

  .vision-video-content p {
    font-size: 11px;
  }

  .impact-section {
    height: auto;
    padding: 30px 0;
  }

  .impact-cards-section {
    display: grid;
    grid-template-columns: auto;
    row-gap: 10px;
    margin-top: 20px;
  }

  .engage-section .left-contianer {
    width: 55%;
  }

  .engage-section .left-contianer p {
    font-size: 14px;
    margin: 0px 0 0 20px;
  }

  .engage-section .right-contianer button {
    font-size: 11px;
    padding: 8px 30px;
    border-radius: 30px;
    background-color: #d56846;
  }

  .team-grid-section {
    width: auto;
  }

  .team-contact-section {
    min-height: 90vh;
  }

  .team-contact-container {
    width: 80%;
    height: 100%;
    flex-direction: column;
  }

  .team-contact-vecor {
    display: none;
  }

  .team-contact-left {
    width: 100%;
    height: auto;
    text-align: center;
    /* background-color: rgb(222, 91, 91); */
  }

  .team-contact-left h2 {
    font-size: 28px;
    letter-spacing: 1.5px;
    line-height: 35px;
    font-weight: 600;
    color: #7b3c2e;
  }

  .team-contact-left p {
    font-size: 14px;
    color: #000;
  }

  .team-contact-right {
    width: 100%;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* background-color: rgb(222, 185, 91); */
  }

  .state-work-container {
    flex-direction: column;
  }

  .state-work-left-container {
    width: 100%;
    border-right: none;
    border-bottom: 1.5px dashed #7b3c2e4a;
  }

  .state-work-right-container {
    width: 100%;
    /* background-color: rgb(153, 109, 51); */
  }

  .state-work-grid-container {
    display: grid;
    grid-template-columns: auto;
    justify-content: center;
  }

  .state-work-detail-top-detail {
    width: 90%;
  }

  .state-work-detail-top-detail h2 {
    font-size: 22px;
    line-height: 1.8rem;
    font-weight: 600;
  }

  .state-work-detail-bottom-container {
    display: flex;
    flex-direction: column-reverse;
  }

  .detail-bottom-container-right {
    width: 100%;
    padding: 0px 0px 20px 0px;
  }

  .work-detail-tag-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 0;
  }

  .state-work-btn-tag {
    margin-top: 15px;
    margin-left: 10px;
  }

  .detail-bottom-container-left {
    width: 100%;
    border-top: 1.8px dashed #7b3c2ecf;
    border-right: none;
  }

  .detail-bottom-container-left .heading {
    width: calc(100% + 100px);
    padding: 15px 20px 15px 65px;
  }

  .detail-bottom-container-left .highlighted-paragraph {
    width: calc(100% + 100px);
    padding: 15px 20px 15px 65px;
  }

  .detail-bottom-container-left .tags-paragraph {
    width: calc(100% + 100px);
    padding: 15px 20px 15px 65px;
  }

  .detail-bottom-container-left .tags-para-tag-container {
    margin-bottom: 25px;
  }

  .tags-para-btn-tag {
    width: fit-content;
    padding: 8px 28px;
    margin-top: 15px;
    margin-right: 15px;
  }

  .tags-para-btn-tag:nth-child(n + 2) {
    margin-left: 0px;
  }

  .lib-detail-top-detail {
    width: 90%;
  }

  .lib-detail-top-detail h2 {
    font-size: 22px;
    line-height: 1.8rem;
    font-weight: 600;
  }

  .lib-detail-exp-top {
    height: 65vh;
  }

  .lib-detail-bottom-high-sec-dark {
    width: 100%;
    min-height: 120px;
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    background-color: rgba(238, 195, 177, 0.3);
  }

  .lib-detail-bottom-high-sec-light {
    width: 100%;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
  }

  .lib-detail-bottom-high-left {
    width: 80%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .lib-detail-bottom-high-left p {
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    margin: 0;
  }

  .lib-detail-bottom-high-right {
    width: 90%;
    padding-left: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-left: 8px solid rgba(123, 60, 46, 0.2);
  }

  .event-top-container {
    background-position-y: 45vh;
  }

  .event-round-section {
    min-height: 100vh;
  }

  .event-round-container {
    margin: auto;
    width: 90%;
    height: 100%;
    font-family: Poppins;
    /* background-color: #d56846; */
  }

  .event-round-info-container {
    margin: auto;
    width: 100%;
    text-align: center;
    /* background-color: #e5ac9f; */
  }

  .round-div-container {
    grid-template-columns: auto;
  }

  .event-workshop-card-container {
    grid-template-columns: auto;
  }

}

.blog-field-division_blank {
  width: 20% !important;
}

.blog-field-division {
  width: 80% !important;
  padding: 25px 30px 25px 17px;
}

.sidebar {
  height: 500px;
  position: fixed;
  top: 0%;
  right: -100vw;
  /* Start off-screen */
  z-index: 100;
  border: 1px solid #fff;
  background: radial-gradient(66.64% 208.91% at 20.88% 10.33%,
      rgba(255, 255, 255, 0.8) 0%,
      rgba(255, 255, 255, 0.3) 100%) !important;
  backdrop-filter: blur(5px) !important;
  width: 60%;
  transition: right 0.3s ease;
  /* Animation transition */
}

.sidebar.open {
  right: 0;
  /* Slide in from right */
  height: 500px;
  border: 1px solid #fff;
  background: radial-gradient(66.64% 208.91% at 20.88% 10.33%,
      rgba(255, 255, 255, 0.8) 0%,
      rgba(255, 255, 255, 0.3) 100%) !important;
  backdrop-filter: blur(5px) !important;
}

.nav-link {
  text-align: center;
}

.navbar>.container-fluid {
  justify-content: center;
}

.mob-headerLink {
  width: fit-content;
  color: #000 !important;
  font-family: Poppins !important;
  font-size: 15px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

.field-map-locations {
  width: 500px;
}

.field-desc-title {
  color: #000;
  font-family: Poppins;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.field-desc-desc {
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.field-desc-location-title {
  color: #000;
  font-family: Poppins;
  font-size: 15px;
  margin-top: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.field-desc-location-desc {
  color: #000;
  font-family: Poppins;
  font-size: 13.5px;
  margin-top: -7px;
  padding-top: 0px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.field-desc-location-button {
  border-radius: 30px;
  border: 1px solid #7b3c2e;
  background: #fff;
  color: var(--Midium, #d56846);
  font-family: Poppins;
  font-size: 12px;
  padding: 7px 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.odisha-work-map-image {
  margin-bottom: -40px;
}

.field-landing-page {
  background-color: #f9f2ef;
  padding-top: 60px;
  margin-top: -50px;
}

.blog-title-text {
  color: var(--Dark, #7b3c2e);
  text-align: right;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 10px;
}

.blog-desc-text {
  color: #000;
  font-family: Poppins;
  font-size: 13px;
  padding: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.blog-details-2019 {
  background: #d9ac98;
  height: 120px;
  width: 80% !important;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  transition: 0.3s ease-in;
}

.blog-details-2019-mob {
  background: #d9ac98;
  height: 120px;
  width: 60% !important;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  transition: 0.3s ease-in;
}

.blog-details-2019-not-active {
  background: #d9ac98;
  height: 120px;
  width: 10% !important;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  transition: 0.3s ease-in;
}

.blog-details-2019-not-active-mob {
  background: #d9ac98;
  height: 120px;
  width: 20% !important;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  transition: 0.3s ease-in;
}

.blog-details-2019-not-active p {
  color: var(--Dark, #7b3c2e);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.blog-details-2019-not-active-mob p {
  color: var(--Dark, #7b3c2e);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.blog-details-2019 p {
  color: #000;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.blog-details-2019-mob p {
  color: #000;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.blog-details-2021 {
  background: var(--Light, #e68662);
  height: 120px;
  display: flex;
  justify-content: flex-end;
  transition: 0.3s ease-in;
  align-items: flex-end;
  width: 80% !important;
}

.blog-details-2021-mob {
  background: var(--Light, #e68662);
  height: 120px;
  display: flex;
  justify-content: flex-end;
  transition: 0.3s ease-in;
  align-items: flex-end;
  width: 60% !important;
}

.blog-details-2021 p {
  color: #000;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.blog-details-2021-mob p {
  color: #000;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.blog-details-2021-not-active {
  background: var(--Light, #e68662);
  height: 120px;
  display: flex;
  justify-content: flex-end;
  transition: 0.3s ease-in;
  align-items: flex-end;
  width: 10% !important;
}

.blog-details-2021-not-active-mob {
  background: var(--Light, #e68662);
  height: 120px;
  display: flex;
  justify-content: flex-end;
  transition: 0.3s ease-in;
  align-items: flex-end;
  width: 20% !important;
}

.blog-details-2021-not-active p {
  color: var(--Dark, #7b3c2e);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.blog-details-2021-not-active-mob p {
  color: var(--Dark, #7b3c2e);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.blog-details-2023 {
  background: #f7c9b4;
  height: 120px;
  display: flex;
  transition: 0.3s ease-in;
  justify-content: flex-end;
  align-items: flex-end;
  width: 80% !important;
}

.blog-details-2023-mob {
  background: #f7c9b4;
  height: 120px;
  display: flex;
  transition: 0.3s ease-in;
  justify-content: flex-end;
  align-items: flex-end;
  width: 60% !important;
}

.blog-details-2023 p {
  color: #000;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.blog-details-2023-mob p {
  color: #000;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.blog-details-2023-not-active {
  background: #f7c9b4;
  height: 120px;
  display: flex;
  transition: 0.3s ease-in;
  justify-content: flex-end;
  align-items: flex-end;
  width: 10% !important;
}

.blog-details-2023-not-active-mob {
  background: #f7c9b4;
  height: 120px;
  display: flex;
  transition: 0.3s ease-in;
  justify-content: flex-end;
  align-items: flex-end;
  width: 20% !important;
}

.blog-details-2023-not-active p {
  padding-right: 10px;
  color: var(--Dark, #7b3c2e);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.blog-details-2023-not-active-mob p {
  padding-right: 10px;
  color: var(--Dark, #7b3c2e);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.blog-details-by-year {
  display: flex !important;
  flex-direction: row !important;
}

.blog-field-img {
  width: 100%;
}

.blog-field-img-desc {
  color: #000;
  margin-top: 10px;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.blog-details-button {
  border-radius: 30px;
  background: #d56846;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  border: none;
  font-size: 12px;
  padding: 5px 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.library-video-section {
  /* background-image: url('./assests/images/eventsBg.png') ; */
  position: relative;
  width: 100%;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  padding-left: 30px;
  padding-bottom: 10px;
  flex-direction: row;
  overflow: hidden;
}

.pubslist {
  padding-bottom: 0px !important;
  align-items: flex-end !important;
}

.update-text-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  padding-top: 55px;
  padding-bottom: 40px;
  flex-wrap: nowrap !important;
}

.update-text {
  color: var(--Dark, #7b3c2e);
  font-family: Poppins;
  font-size: 27px;
  font-style: normal;
  font-weight: 500;
  width: 450px !important;
  line-height: normal;
  letter-spacing: 2.4px;
}

.update-text-mob {
  color: var(--Dark, #7b3c2e);
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  margin-top: 10px;
  width: 90%;
  line-height: normal;
  letter-spacing: 2.4px;
}

.update-text-input {
  fill: linear-gradient(12deg,
      rgba(217, 172, 152, 0.5) 56.23%,
      rgba(255, 255, 255, 0.5) 95.77%);
  opacity: 1;
  border-radius: 30px;
  border: 1.5px solid #7b3c2e;
  display: flex;
  width: 250px;
}

.update-text-input::placeholder {
  font-size: 12px;
  padding-left: 10px;
  display: flex;
  color: #000;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  align-items: center;
}

.update-text-subscrib-button {
  border-radius: 30px;
  background: var(--Midium, #d56846);
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  width: fit-content;
  margin-top: 10px;
  padding: 5px 40px;
  border: none;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.video-preview-title {
  color: #7b3c2e;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0px;
}

.video-preview-desc {
  color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.video-preview-footer {
  display: flex;
  width: 330px;
  align-items: center;
  padding: 5px;
  flex-direction: row;
  background: linear-gradient(0deg, rgba(123, 60, 46, 0.4) 0%, #fff 100%);
  justify-content: space-between;
}

.video-preview-date {
  color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 0px;
}

.library-video-row {
  display: flex;
  flex-direction: row;
  overflow: scroll;
  width: 100%;
}

.library-video-row::-webkit-scrollbar {
  display: none;
}

.play-button {
  width: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.update-title-row {
  color: var(--Dark, #7b3c2e);
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  align-items: center;
  display: flex;
  font-weight: 500;
  justify-content: center;
  line-height: normal;
  letter-spacing: 2.4px;
}

.all-the-updates {
  color: #000;
  font-family: Poppins;
  font-size: 13px;
  margin-bottom: -35px;
  font-style: normal;
  font-weight: 500;
  padding-left: 20px;
  line-height: normal;
}

.update-year {
  color: var(--Dark, #7b3c2e);
  font-family: Poppins;
  font-size: 40px;
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.update-button {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  border: none;
  font-size: 12px;
  font-style: normal;
  padding: 5px 15px;
  font-weight: 500;
  background-color: #d56846;
  border-radius: 15px;
  margin-bottom: 10px;
  line-height: normal;
}

.all-video {
  width: 150px !important;
  border-radius: 30px;
  background: var(--Midium, #d56846);
  color: #fff;
  text-align: center;
  padding: 7px 12px;
  font-family: Poppins;
  margin-bottom: 50px;
  border: none;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.pubs-header-card {
  background: rgba(123, 60, 46, 0.4);
  box-shadow: 8px 9px 33.2px 0px rgba(255, 255, 255, 0.2);
  width: 230px !important;
  transition: 0.2s ease-in;
  border: 3px solid #fff;
  padding: 15px;
  margin-right: 25px;
}

.join-us-card {
  background: rgba(123, 60, 46, 0.4);
  box-shadow: 8px 9px 33.2px 0px rgba(255, 255, 255, 0.2);
  box-shadow: 8px 9px 33.2px 0px rgba(255, 255, 255, 0.2);
  width: 230px !important;
  transition: 0.2s ease-in;
  border: 3px solid #fff;
  padding: 25px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: 25px;
}

.stories-header-card {
  background: rgba(123, 60, 46, 0.4);
  box-shadow: 8px 9px 33.2px 0px rgba(255, 255, 255, 0.2);
  width: 200px !important;
  transition: 0.2s ease-in;
  border: 3px solid #fff;
  padding: 15px;
  margin-right: 25px;
  transition: 0.3s ease-in;
}

.stories-card-desc {
  display: none;
}

.stories-header-card:hover {
  width: 460px !important;
  background-color: #d56846;
}

.stories-header-card:hover .stories-card-desc {
  display: block;
  font-size: 12px;
}

.stories-header-card:hover .stories-card-title-text {
  display: block;
  font-size: 19px;
  color: white;
}

.stories-header-card:hover .stories-card-source {
  display: none;
}

.stories-header-card:hover .stories-card-title {
  font-size: 20px;
}

.stories-card-title-text {
  display: none;
}

.pubs-card-date {
  color: #fff;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.pubs-card-title {
  color: #fff;
  font-family: Poppins;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.stories-card-title {
  color: #fff;
  font-family: Poppins;
  font-size: 24px;
  width: 150px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.pubs-card-desc {
  display: none;
}

.pubs-card-date1 {
  display: none;
}

.pubs-card-source {
  color: #000;
  font-family: Poppins;
  font-size: 12px;
  width: 120px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}

.stories-card-source {
  color: #000;
  font-family: Poppins;
  font-size: 13px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}

.pubs-header-card:hover {
  transform: scale(1.02);
  background-color: #d56846;
}

.pubs-header-card:hover .pubs-card-source {
  font-size: 11px;
  width: 100%;
  margin-bottom: 0px;
}

.pubs-header-card:hover .pubs-card-desc {
  display: block;
  font-size: 12px;
}

.pubs-header-card:hover .pubs-card-title {
  font-size: 14px;
}

.pubs-header-card:hover .pubs-card-date {
  display: none;
}

.pubs-header-card:hover .pubs-card-date1 {
  display: block;
  font-size: 11px;
  margin-bottom: 0px;
  color: white;
}

.pubs-library-section {
  width: 100%;
  height: 100vh;
}

.pubs-library-container {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.stories-library-container {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: flex-start;
}

.publication-list-title {
  color: var(--Dark, #7b3c2e);
  text-align: right;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1px;
  width: fit-content !important;
  margin-bottom: 0px;
}

.pubs-search-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.publication-list-input {
  border-radius: 30px;
  border: 1px solid var(--Dark, #7b3c2e);
  width: 250px !important;
}

.publication-list-input::placeholder {
  color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.pubs-article-card {
  background: linear-gradient(90deg, #f7c9b4 0%, rgba(255, 255, 255, 0) 100%);
  width: 100%;
  padding: 15px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.pubs-article-title {
  color: var(--Dark, #7b3c2e);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.pubs-article-desc {
  color: #000;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.pubs-article-writer-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.pubs-article-user-details {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pubs-article-user-details p {
  font-size: 12px;
  color: #000;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.pubs-article-social-media {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.pubs-article-social-media p {
  margin-bottom: 0px;
}

.tag-button {
  border-radius: 30px;
  background: rgba(218, 134, 97, 0.39);
  color: #d56846;
  border: none;
  padding: 5px 12px;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  margin: 7px;
  line-height: normal;
}

.vision-page-title-text {
  color: var(--Dark, #7b3c2e);
  text-align: right;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 8px;
  letter-spacing: 2.4px;
}

.vision-page-title-desc {
  color: var(--Dark, #7b3c2e);
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  width: 55%;
  font-weight: 400;
  line-height: normal;
}

.meet-our-team {
  border-radius: 30px;
  background: #d56846;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  border: none;
  padding: 5px 12px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.vision-container {
  background: linear-gradient(159deg,
      #7b3c2e 14.01%,
      rgba(225, 110, 84, 0.6) 130.7%);
  width: 35% !important;
  height: 100%;
}

.vision-container-1 {
  background: linear-gradient(159deg,
      #7b3c2e 14.01%,
      rgba(225, 110, 84, 0.6) 130.7%);
  width: 100% !important;
  height: 100%;
}

.vision-row {
  width: 100%;
  height: 100%;
  padding: 0px !important;
  margin: 0px !important;
  justify-content: flex-end !important;
}

.our-vision-text {
  color: #fff;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  width: 100%;
  justify-content: center;
  line-height: normal;
  margin-top: 10px;
  margin-left: 0px;
  letter-spacing: 2.4px;
}

.our-vision-desc-text {
  color: #fff;
  font-family: Poppins;
  margin-left: 20px;
  margin-top: 0px;
  font-size: 10.5px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.join-us-title {
  color: var(--Dark, #7b3c2e);
  text-align: right;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  width: fit-content;
  margin-bottom: 0px;
  width: fit-content !important;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 2.4px;
  margin-bottom: 20px;
}

.join-us-button {
  border-radius: 30px;
  border: none;
  width: fit-content !important;
  padding: 5px 12px;
  background: #d56846;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.join-internships {
  color: var(--Midium, #d56846);
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  border: none;
  width: 120px;
  padding: 5px 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 30px;
  background: #fff;
}

.join-us-card-desc {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.join-us-card-title {
  color: #fff;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.join-us-card-row {
  display: flex;
  justify-content: center;
}

.media-mention-title {
  color: #fff;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.media-mention-desc {
  color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.media-mention-button {
  border-radius: 30px;
  background: #fff;
  color: var(--Midium, #d56846);
  font-family: Poppins;
  border: none;
  padding: 5px 12px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: fit-content;
}

.vision-video-section {
  /* background-image: url('./assests/images/eventsBg.png') ; */
  position: relative;
  width: 100%;
  min-height: 55vh;
  display: flex;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  padding-left: 30px;
  padding-bottom: 10px;
  flex-direction: row;
  overflow: hidden;
}

.team-tagline {
  color: var(--Dark, #7b3c2e);
  text-align: right;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: fit-content !important;
  letter-spacing: 2.4px;
  margin-top: 20px !important;
}

.event-page-title {
  color: var(--Dark, #7b3c2e);
  font-family: Poppins;
  font-size: 13px;
  width: 75%;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.event-know-more-button {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  width: fit-content;
  padding: 5px 12px;
  border: none;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 30px;
  background: var(--Midium, #d56846);
}

.event-footer-desc {
  padding: 20px;
  padding-left: 46px;
  background: #f9f2ef;
}

.our-workshop {
  color: #fff;
  font-family: Poppins;
  font-size: 21px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.our-workshop-desc {
  color: #000;
  font-family: Poppins;
  font-size: 13px;
  width: 80%;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.our-workshop-check-out-button {
  color: var(--Midium, #d56846);
  font-family: Poppins;
  font-size: 12px;
  padding: 5px 15px;
  border: none;
  font-style: normal;
  font-weight: 500;
  width: 150px !important;
  line-height: normal;
  border-radius: 30px;
  background: #fff;
}

.our-workshop-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  margin-bottom: 20px;
}

.round-table {
  color: var(--Dark, #7b3c2e);
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  width: fit-content;
  font-weight: 900;
  line-height: normal;
}

.round-table-desc {
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  width: 70%;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.round-table-row {
  margin: 0px !important;
  padding: 0px !important;
  padding-left: 40px !important;
  /* padding-top: 20px; */
  align-items: center;
  justify-content: center;
}

.round-table-know-more {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #d56846 !important;
  font-family: Poppins;
  font-size: 12px;
  color: white;
  padding: 5px 15px;
  border: none;
  font-style: normal;
  font-weight: 500;
  width: 150px !important;
  line-height: normal;
  border-radius: 30px;
  background: #fff;
}

.round-table-poster-desc {
  color: #000;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  width: 40%;
  line-height: normal;
}

.roundTable-map-container-row {
  flex-wrap: nowrap !important;
  align-items: flex-end;
  width: 100vw;
  overflow: hidden;
}

.round-table-poster-desc-button {
  color: var(--Midium, #d56846);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  border: none;
  margin-bottom: 25px;
  padding: 5px 25px;
  font-weight: 500;
  border-radius: 30px;
  background: #fff;
  line-height: normal;
}

.round-table-workshop-card {
  background: #fff;
  margin-right: 20px;
  border: 1px solid #7b3c2e;
  padding: 10px 10px 0px 10px;
  width: 200px;
}

.round-table-workshop-card-day {
  color: var(--Dark, #7b3c2e);
  font-family: Poppins;
  font-size: 56px;
  margin-top: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 5px;
}

.round-table-workshop-card-month {
  color: var(--Dark, #7b3c2e);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 5px;
}

.round-table-workshop-card-desc {
  color: var(--Dark, #7b3c2e);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 35px;
  width: 70%;
  line-height: normal;
}

.round-table-workshop-card-location {
  color: var(--Dark, #7b3c2e);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 5px;
  line-height: normal;
}

.round-table-register-now-button {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  padding: 7px 10px;
  width: 200px;
  margin-top: 10px;
  border: none;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 30px;
  background: var(--Midium, #d56846);
}

.Roundtables {
  color: var(--Dark, #7b3c2e);
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  width: fit-content;
  font-weight: 500;

  line-height: normal;
  letter-spacing: 2.4px;
}

.Roundtables-desc {
  color: var(--Dark, #7b3c2e);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  width: 100%;
  font-weight: 400;
  line-height: normal;
}

.roundtable-card {
  border: 1px solid var(--Dark, #7b3c2e);
  background: #fff;
  width: 180px;
  margin-top: 10px;
  padding: 10px;
}

.roundtable-card-title {
  color: var(--Dark, #7b3c2e);
  font-family: Poppins;
  font-size: 14px;
  margin-top: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.roundtable-card-desc {
  color: var(--Dark, #7b3c2e);
  font-family: Poppins;
  margin-top: 5px;
  font-size: 13px;
  width: 80%;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.roundtable-card-date {
  color: var(--Dark, #7b3c2e);
  font-family: Poppins;
  font-size: 11px;
  font-style: italic;
  font-weight: 300;
  line-height: normal;
  margin-top: 15px;
  margin-bottom: 7px;
}

.round-table-arrow-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.about-the-project-text {
  width: 10% !important;
}

.blog-desc-text-division {
  width: 80% !important;
}

.about-the-project-text-mob {
  width: 100% !important;
}

/* .blog-desc-text-division-mob{

} */
.blog-field-division-row {
  justify-content: flex-end;
}

.jharfra-title {
  color: var(--Dark, #7b3c2e);
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  margin-top: 10px;
  width: fit-content;
  font-weight: 700;
  line-height: normal;
}

.jharfra-desc {
  color: #000;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.jharfra-text {
  border-right: 2px solid #7b3c2e;
  border-right-style: dashed;
}

.blog-details-button-mob {
  display: none;
}

.jamia-title {
  color: var(--Dark, #7b3c2e);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 10px;
}

.jamia-desc {
  color: #000;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 15px;
  border-left: 8px solid #cab1ab;
}

.jamia-desc-main-impact-heading {
  background: rgba(123, 60, 46, 0.4);
  padding: 15px 0px 15px 25px;
  margin-bottom: 10px;
}

.jamia-desc-main-impact-heading-text {
  color: #fff;
  font-family: Poppins;
  font-size: 13px;
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.jamia-desc-subtitle {
  color: #7b3c2e;
  font-family: Poppins;
  font-size: 14px;
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 5px;
  line-height: normal;
}

.blog-carousel-row {
  border: 1px solid var(--Dark, #7b3c2e);
  background: #fff;
  align-items: center;
  margin-left: 10px !important;
  margin-right: 10px !important;
  height: 235px;
}

.blog-carousel-img {
  border: 1px solid var(--Dark, #7b3c2e);
  width: 100%;
  height: 235px;
  object-fit: cover;
}

.blog-carousel-title-div {
  /* border: 1px solid var(--Dark, #7B3C2E); */
  background: #fff;
  width: 100%;
}

.blog-carousel-title {
  color: var(--Dark, #7b3c2e);
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  width: 50%;
  margin-top: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.blog-carousel-desc {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.smi-header {
  height: 100px;
  width: 100%;
}

.smi-title-text {
  color: var(--Dark, #7b3c2e);
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.4px;
}

.smi-desc-text {
  color: var(--Dark, #7b3c2e);
  font-family: Poppins;
  font-size: 12px;
  font-style: italic;
  font-weight: 300;
  line-height: normal;
}

.smi-title-col {
  padding-left: 20px;
  padding-right: 20px;
}

.smi-about-the-project-div {
  background: #eec3b1;
  padding: 15px;
}

.smi-about-the-project-div-text {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 0px;
  line-height: normal;
}

.hp_colab_text {
  color: var(--Dark, #7b3c2e);
  text-align: center;
  font-family: Poppins;
  margin-bottom: 0px;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.hp_colab_left_border {
  margin: 0px;
  padding: 10px;
  border-left: 5px solid rgba(123, 60, 46, 0.2);
}

.hp_colab_div {
  padding-top: 5px;
  background: rgba(238, 195, 177, 0.3);
  padding: 0px;
  align-items: center;
}

.project-card-footer-text {
  font-size: 10px;
  padding: 5px;
  text-decoration: none;
  color: black;
}

@media screen and (min-width: 320px) and (max-width: 457px) {
  .our-vision-desc-text {
    font-size: 9px;
  }

  .home-events-section {
    min-height: 80vh;
  }
}

@media screen and (max-width: 768px) {
  .work-landing-top {
    width: 100%;
    height: 100vh;
  }

  .state-work-top-container {
    position: relative;
    height: 100vh;
  }

  .state-work-landing-top {
    width: 100%;
    height: 100%;
  }

  .map-container-main {
    position: absolute;
    width: auto;
    height: auto;
    bottom: 5%;
    left: 10%;
    /* background-color: red; */
    z-index: 10;
  }

  .state-map-container-main {
    position: absolute;
    width: auto;
    height: auto;
    bottom: 5%;
    left: 10%;
    z-index: 10;
    /* background-color: red; */
  }

  .map-img-main {
    height: 42vh;
  }

  .top-details-dropdown {
    width: 100%;
    padding: 0px;
    text-align: center;
  }

  .state-top-details-dropdown {
    width: 100%;
    padding: 0px;
    text-align: center;
  }

  .top-details-dropdown h2 {
    font-size: 20px;
  }

  .state-top-details-dropdown h2 {
    font-size: 20px;
  }

  .top-details-dropdown p {
    font-size: 10px;
  }

  .state-top-details-dropdown p {
    font-size: 10px;
  }

  .custom-dropdown-toggle {
    font-size: 12px !important;
    font-weight: 500 !important;
    padding: 8px 18px !important;
    border: 2px solid #fff !important;
  }

  .custom-dropdown-toggle::after {
    color: #fff !important;
    margin-left: 20px !important;
    /* Adjust this value to increase or decrease the gap */
  }

  .top-details {
    width: 90%;
    height: 80%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0%;
    /* background-color: rebeccapurple; */
  }

  .state-top-details {
    width: 90%;
    height: 80%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0%;
    /* background-color: rebeccapurple; */
  }

  .top-details-info {
    width: 100%;
    font-size: 11px;
    font-family: Poppins;
    font-weight: 200;
    color: #fff;
    margin: 0;
  }

  .state-top-details-info {
    width: 100%;
    font-size: 11px;
    font-family: Poppins;
    font-weight: 200;
    color: #fff;
    margin: 0;
  }

  .top-details-info p {
    font-size: 9px;
    text-align: center;
  }

  .state-top-details-info p {
    font-size: 9px;
    text-align: center;
  }

  .work-grid-container {
    grid-template-columns: auto;
  }

  .events-container {
    flex-direction: column;
    align-items: center;
    top: 30%;
    /* background-color: #9cf1a5; */
  }

  .events-form {
    width: auto;
    padding: 0px;
    margin-top: 20px;
    /* justify-content: center; */
    /* background-color: #7b7575; */
  }

  .workshop-container {
    height: 350px;
  }

  .workshop-img-container img {
    width: 100%;
    /* background-color: #22762b; */
  }

  .know-btn {
    margin-top: 10px;
  }

  .lib-pubs-section {
    height: 80vh;
  }

  .lib-bottom-section {
    height: 350px;
  }

  .lib-bottom-container {
    flex-direction: column;
  }

  .lib-bottom-left {
    width: 100%;
    /* background-color: #eab6ab; */
  }

  .lib-bottom-right {
    width: 80%;
    height: 45%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* background-color: yellowgreen; */
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .work-landing-top {
    width: 100%;
    height: 100vh;
  }

  .state-work-top-container {
    position: relative;
    height: 100vh;
  }

  .state-work-landing-top {
    width: 100%;
    height: 100%;
  }

  .map-container-main {
    position: absolute;
    width: auto;
    height: auto;
    bottom: 5%;
    left: 20%;
    /* background-color: red; */
    z-index: 10;
  }

  .state-map-container-main {
    position: absolute;
    width: auto;
    height: auto;
    bottom: 5%;
    left: 20%;
    z-index: 10;
    /* background-color: red; */
  }

  .map-img-main {
    height: 55vh;
  }

  .top-details-dropdown {
    width: 100%;
    padding: 0px;
    text-align: center;
  }

  .state-top-details-dropdown {
    width: 100%;
    padding: 0px;
    text-align: center;
  }

  .top-details-dropdown h2 {
    font-size: 28px;
  }

  .state-top-details-dropdown h2 {
    font-size: 28px;
  }

  .top-details-dropdown p {
    font-size: 14px;
  }

  .state-top-details-dropdown p {
    font-size: 14px;
  }

  .custom-dropdown-toggle {
    font-size: 12px !important;
    font-weight: 500 !important;
    padding: 8px 18px !important;
    border: 2px solid #fff !important;
  }

  .custom-dropdown-toggle::after {
    color: #fff !important;
    margin-left: 20px !important;
    /* Adjust this value to increase or decrease the gap */
  }

  .top-details {
    width: 90%;
    height: 80%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0%;
    /* background-color: rebeccapurple; */
  }

  .state-top-details {
    width: 90%;
    height: 85%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0%;
    /* background-color: rebeccapurple; */
  }

  .top-details-info {
    width: 100%;
    font-size: 11px;
    font-family: Poppins;
    font-weight: 200;
    color: #fff;
    margin: 0;
  }

  .state-top-details-info {
    width: 100%;
    font-size: 11px;
    font-family: Poppins;
    font-weight: 200;
    color: #fff;
    margin: 0;
  }

  .top-details-info p {
    font-size: 14px;
    text-align: center;
  }

  .state-top-details-info p {
    font-size: 14px;
    text-align: center;
  }

  .lib-pubs-section {
    height: 60vh;
  }

  .state-work-left-container {
    width: 50%;
    /* background-color: rebeccapurple; */
  }

  .state-work-right-container {
    width: 50%;
    /* background-color: rgb(153, 109, 51); */
  }

  .state-work-grid-container {
    display: grid;
    grid-template-columns: auto;
    column-gap: 20px;
    row-gap: 20px;
    padding: 20px 0px;
    justify-content: center;
    /* background-color: #e78b8b; */
  }

  .state-work-detail-top-detail {
    width: 80%;
  }

  .lib-detail-top-detail {
    width: 80%;
  }

  .lib-detail-exp-top {
    height: 30vh;
  }

  .lib-detail-bottom-high-left {
    width: 15%;
  }

  .event-top-container {
    background-position-y: 70vh;
  }

  .event-round-section {
    min-height: 65vh;
  }

  .event-workshop-section {
    height: 55vh;
  }
}

@media screen and (min-width: 768px) and (max-width: 1100px) {
  .work-grid-container {
    grid-template-columns: auto auto;
  }

  .footer-container {
    width: 100%;
    display: flex;
  }

  .home-pubs-section {
    min-height: 45vh;
  }

  .home-events-section {
    min-height: 50vh;
  }

  .stake-carousel {
    height: 400px;
  }

  .lib-stake-carousel {
    height: 400px;
  }

  .home-page-title-row {
    top: 80%;
  }

  .home-pubs-division-title {
    font-size: 14px;
  }

  .home-pubs-division-desc {
    font-size: 10px !important;
  }

  .home-event-card {
    height: 220px;
    transition: 0.3s ease-in;
    width: 170px;
  }

  .home-event-card-location-section {
    margin-top: 0px !important;
  }

  .our-vision-desc-text {
    font-size: 9px;
  }

  .card-info h1 {
    font-size: 40px !important;
  }

  .bunch-section {
    width: 100vw;
    height: 220px;
  }

  .vision-section {
    width: 100vw;
    height: 60vh;
  }

  .impact-section {
    width: 100vw;
    height: 75vh;
  }

  .impact-cards-section {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 15px;
    row-gap: 15px;
    justify-self: center;
  }

  .impact-cards-section>.impact-card:nth-child(3) {
    grid-column: 1 / -1;
    /* Span the last card across both columns */
    justify-self: center;
    /* Center the card itself */
  }

  .engage-section .left-contianer {
    color: #7b3c2e;
    width: 72%;
  }

  .team-contact-section {
    height: 40vh;
  }

  .team-contact-vecor {
    top: 45%;
    left: -13%;
  }

  .team-contact-left {
    width: 50%;
  }

  .team-contact-vecor .team-contact-dot {
    width: 420px;
  }

  .team-contact-vecor .team-contact-badge {
    top: 35%;
    right: 6%;
  }
}

@media (width: 768px) {
  .vision-section {
    width: 100vw;
    height: 70vh;
  }

  .impact-section {
    width: 100vw;
    height: 85vh;
  }

  .impact-cards-section {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 15px;
    row-gap: 15px;
    justify-self: center;
  }

  .impact-cards-section>.impact-card:nth-child(3) {
    grid-column: 1 / -1;
    /* Span the last card across both columns */
    justify-self: center;
    /* Center the card itself */
  }

  .home-events-section {
    min-height: 60vh;
  }

  .event-round-section {
    min-height: 70vh;
  }

  .event-workshop-section {
    height: 70vh;
  }
}

@media screen and (min-width: 1100px) and (max-width: 1300px) {
  .our-vision-desc-text {
    font-size: 11px;
  }

  .stake-carousel {
    height: 400px;
  }

  .lib-stake-carousel {
    height: 450px;
  }

  .team-contact-vecor {
    position: absolute;
    top: 48%;
    left: -13%;
    /* background-color: #b090902d; */
  }

  .team-contact-vecor .team-contact-dot {
    width: 450px;
  }

  .team-contact-vecor .team-contact-badge {
    top: 32%;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1600px) {
  .header_logo {
    width: 175px;
  }

  .our-vision-desc-text {
    font-size: 14px;
  }

  .stake-carousel {
    height: 480px;
  }

  .lib-stake-carousel {
    height: 480px;
  }

  .headerLink {
    color: #fff !important;
    font-family: Poppins;
    font-size: 18px !important;
    font-family: "Poppins", sans-serif !important;
    font-weight: 600;
    margin-right: 52px !important;
  }

  .vision-page-title-desc {
    font-size: 16px;
  }

  .vision-page-title-text {
    font-size: 32px;
  }

  .vision-video-section {
    min-height: 25vh;
  }

  .about-team-image-1 {
    width: 70% !important;
  }

  .field-map-image-title {
    font-size: 34px;
  }

  .headerLink1 {
    font-family: Poppins;
    font-size: 18px !important;
    font-family: "Poppins", sans-serif !important;
    font-weight: 600;
    margin-right: 52px !important;
  }

  .home-page-title-row {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 2;
  }

  .home-page-title {
    font-size: 36px;
  }

  /* .home-project-image{
    height: 250px;
  } */
  /* .home-project-desc{
    height: 250px;
  } */
  .home-project-desc-title {
    font-size: 17px;
  }

  .home-project-desc-description {
    font-size: 13px;
  }

  .home-publication-title {
    font-size: 26px;
  }

  .home-publication-desc {
    font-size: 15px;
    font-weight: 500;
  }

  .home-pubs-division-texts {
    /* height: 350px; */
  }

  .home-pubs-division-title {
    font-size: 16px;
  }

  .home-pubs-division-desc {
    font-size: 14px !important;
  }

  /* .home-pubs-division-texts-border{
    padding: 8px 0px 0px 13px;
  } */
  .project-card-footer-text {
    font-size: 12px;
  }

  .home-event-card {
    height: 350px !important;
    width: 250px;
  }

  .home-event-card-location-section {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 40px;
    justify-content: space-between;
    margin-top: 110px !important;
  }

  .map-image-text {
    width: 40%;
  }

  .map-image-title {
    font-size: 26px;
  }

  .map-image-desc {
    font-size: 19px;
  }

  .field-desc-desc {
    font-size: 15px;
  }

  .field-desc-location-title {
    font-size: 18px;
  }

  .team-contact-section {
    height: 60vh;
  }

  .team-contact-vecor {
    position: absolute;
    top: 35%;
    left: -13%;
    /* background-color: #b090902d; */
  }

  .team-contact-vecor .team-contact-dot {
    width: 500px;
  }

  .team-contact-vecor .team-contact-badge {
    top: 30%;
  }
}

@media screen and (min-width: 1600px) {
  .header_logo {
    width: 185px;
  }

  .stake-carousel {
    height: 480px;
  }

  .lib-stake-carousel {
    height: 480px;
  }

  .map-image-text {
    width: 100%;
  }

  .map-image-title {
    font-size: 26px;
  }

  .map-image-desc {
    font-size: 19px;
  }

  .field-desc-desc {
    font-size: 15px;
  }

  .field-desc-location-title {
    font-size: 18px;
  }

  .headerLink {
    color: #fff !important;
    font-family: Poppins;
    font-size: 18px !important;
    font-family: "Poppins", sans-serif !important;
    font-weight: 600;
    margin-right: 52px !important;
  }

  .home-page-title-row {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 0;
  }

  .home-page-title {
    font-size: 31px;
  }

  /* .home-project-image{
    height: 290px;
  } */
  /* .home-project-desc{
    height: 290px;
  } */
  .home-project-desc-title {
    font-size: 19px;
  }

  .home-project-desc-description {
    font-size: 14px;
  }

  .home-publication-title {
    font-size: 28px;
  }

  .home-publication-desc {
    font-size: 17px;
  }

  .home-pubs-division-texts {
    /* height: 360px; */
  }

  .home-pubs-division-title {
    font-size: 18px;
  }

  .home-pubs-division-desc {
    font-size: 16px !important;
  }

  /* .home-pubs-division-texts-border{
    padding: 8px 0px 0px 13px;
  } */
  .project-card-footer-text {
    font-size: 13px;
  }

  .home-event-card {
    height: 350px !important;
    width: 250px;
  }

  .home-event-card {
    height: 360px !important;
    width: 290px;
  }

  .home-event-card-location-section {
    margin-top: 40px !important;
  }

  .home-event-card-location-section {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 40px;
    justify-content: space-between;
    margin-top: 65px !important;
  }

  .home-event-title-name {
    font-size: 30px;
  }

  .home-event-title-desc {
    font-size: 18px;
  }

  .event-subscription-input {
    height: 50px;
  }

  .event-subscription-input::placeholder {
    font-size: 16px;
  }

  .event-subscription-button {
    font-size: 19px;
  }
}

.event-roundtables-title {
  color: var(--Dark, #7b3c2e);
  text-align: center;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 5px;
  line-height: normal;
}

.event-roundtables-row {
  padding-top: 50px;
  background: var(--Background, #f9f2ef);
  justify-content: center;
  align-items: center;
}

.event-roundtables-desc {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  width: 60% !important;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.event-roundtable-1-desc {
  border-radius: 500px;
  border: 2px solid #fff;
  width: 190px;
  height: 190px;
  background: rgba(249, 108, 77, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.event-roundtable-1-desc-text {
  color: var(--Dark, #7b3c2e);
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1px;
  padding-left: 17px;
  font-size: 11px;
}

.event-roundtables-row-1 {
  justify-content: space-evenly;
}

.event-roundtables-row-1-col {
  display: flex;
  justify-content: center;
  margin-top: 25px !important;
}

.event-workshop-row {
  padding-top: 20px;
  background: #d9ac98;
  justify-content: center;
  align-items: center;
}

.event-workshop-title {
  color: var(--Dark, #fff);
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 5px;
  line-height: normal;
}

.event-workshop-row-1-div {
  background: #7b3c2e99;
  border: 2px solid white;
  width: 150px !important;
}

.event-workshop-row-1-div-text {
  color: #fff;
  font-family: Poppins;
  font-size: 11px;
  padding: 30px 7px 30px 7px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1px;
}

.about-us-vision-endline-text {
  color: var(--Dark, #7b3c2e);
  font-family: Poppins;
  font-size: 13px;
  width: fit-content !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
}

.about-us-vision-endline-button {
  border-radius: 30px;
  background: var(--Midium, #d56846);
  color: #fff;
  text-align: center;
  font-family: Poppins;
  border: none;
  padding: 10px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 150px !important;
}

.about-us-vision-endline-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 5px;
  align-items: center;
  flex-wrap: nowrap !important;
  border-bottom: 2px dashed #7b3c2e;
  margin-bottom: 35px;
}

.about-team-image-1 {
  width: 50%;
}

.about-team-image-1-title {
  margin-bottom: 0px;
  width: fit-content;
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.about-team-image-1-desc {
  color: #000;
  width: fit-content;
  text-align: center;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  height: 50px;
  font-weight: 300;
  line-height: 15px;
  /* 136.364% */
}

.about-team-contact-image {
  width: 60%;
}

.about-team-contact-title {
  color: var(--Dark, #7b3c2e);
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  width: 70%;
  line-height: normal;
  letter-spacing: 2.4px;
  padding-left: 150px;
}

.about-team-contact-desc {
  color: #000;
  font-family: Poppins;
  padding-left: 150px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0px;
  line-height: normal;
}

.about-contact-input {
  border-radius: 60px !important;
  border: 1.5px solid #7b3c2e !important;
  width: 65%;
  margin-top: 13px;
  background: transparent !important;
}

.about-team-form {
  padding-left: 150px;
}

.about-contact-input::placeholder {
  color: #000;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.about-contact-input-submit {
  border-radius: 50px;
  border: none;
  padding: 7px 50px;
  background: #d56846;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  margin-top: 10px;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}